import { Pipe, PipeTransform } from '@angular/core';
import normalizeUrl from 'normalize-url';

import { environment } from 'src/environments/environment';

@Pipe({
  name: 'baseUrl',
})
export class BaseUrlPipe implements PipeTransform {
  transform(url?: string, type?: 'rp' | 's3'): string {
    if (!url) {
      return '';
    }

    const fullURL = this.getURL(url, type);

    return normalizeUrl(fullURL);
  }

  private getURL(url: string, type?: 'rp' | 's3') {
    const endpoint = this.getEndpoint(type);

    if (url.startsWith(endpoint)) {
      return url;
    }

    return new URL(url, endpoint).href;
  }

  private getEndpoint(type?: 'rp' | 's3') {
    switch (type) {
      case 'rp':
        return environment.rpEndpoint;
      case 's3':
        return environment.s3Endpoint;
      default:
        return environment.endpoint;
    }
  }
}
