import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IconsModule } from 'src/app/icons/icons.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { HeaderAuthComponent } from './components/header-auth/header-auth.component';
import { HeaderNoAuthComponent } from './components/header-no-auth/header-no-auth.component';
import { HeaderPristineComponent } from './components/header-pristine/header-pristine.component';
import { HeaderContainer } from './containers/header/header.container';

@NgModule({
  declarations: [
    HeaderContainer,
    HeaderAuthComponent,
    HeaderNoAuthComponent,
    HeaderPristineComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    IconsModule,
    SharedModule,
  ],
  exports: [HeaderContainer],
})
export class HeaderModule {}
