<header>
  <div class="container">
    <div class="d-flex align-items-center">
      <a [routerLink]="['/']">
        <svg xmlns="http://www.w3.org/2000/svg" class="colleges-logo" fill="none" viewBox="0 0 40 40">
          <g clip-path="url(#clip0)">
            <path fill="#fff"
              d="M22.6 27.7a5 5 0 01-2.6.7 5 5 0 01-2.6-.7L5.8 20.6s-1-.6-1 .8v7.1c0 3.8 6.8 7.8 15.2 7.8 8.4 0 15.2-4 15.2-7.8V28v-7c0-1.2-.7-.6-.7-.6l-12 7.3z" />
            <path fill="#4FB5BD"
              d="M39.3 15c1-.6 1-1.5 0-2L21.6 4c-.9-.5-2.3-.5-3.2 0L.7 13c-1 .5-1 1.4 0 2l17.7 10.8c.9.5 2.3.5 3.2 0" />
            <path fill="#fff" d="M38.6 31V19s0-.5-.3-.3l-1.2.7c-.3.2-.2.7-.2.7v11l-.2.2a2 2 0 102 0l-.1-.3z" />
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#fff" d="M0 0h40v40H0z" />
            </clipPath>
          </defs>
        </svg>
      </a>
      <div class="text-between-logos">Colleges by</div>
      <a [href]="rpURL" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" class="rp-logo" fill="none" viewBox="0 0 28 20">
          <g clip-path="url(#clip1)">
            <path fill="#fff" fill-rule="evenodd"
              d="M20.7 13.5H17v6H8.5c-4.3 0-8.5-1.1-8.5-6.9 0-4.5 3.2-6.5 7.3-6.5h3.8V0h8.5C23.8 0 28 1.2 28 7c0 4.5-3.2 6.5-7.4 6.5zm-9.6-2.9H8c-1.4 0-2.4.5-2.4 2s.9 2.2 2.6 2.2h2.8v-4.2zm8.7-5.8H17V9H20c1.4 0 2.5-.5 2.5-2.1 0-1.4-.9-2-2.6-2z"
              clip-rule="evenodd" />
          </g>
          <defs>
            <clipPath id="clip1">
              <path fill="#fff" d="M0 0h28v20H0z" />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a [routerLink]="['resources']" routerLinkActive='active' class="d-flex align-items-center ms-auto menu-item">
        <div class="icon">
          <span [icon]="'COLLEGE-RESOURCES:0 0 20 20'"></span>
        </div>
        <div class="title">Resources</div>
      </a>
      <form class="d-flex align-items-center">
        <button type="submit" class="btn" (click)="openLoginModal(); (false)">
          Log in
        </button>
        <a [href]="joinURL" class="btn" target="_blank">Join now</a>
      </form>
    </div>
  </div>
</header>
