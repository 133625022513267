import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserActions } from 'src/app/modules/user/store/actions';

import { IBookmarkedCollege } from '../../model/';
import { BookmarksActions } from '../actions';

export const featureAdapter: EntityAdapter<IBookmarkedCollege> =
  createEntityAdapter<IBookmarkedCollege>({
    selectId: (model) => model.tile_id,
    sortComparer: (a: IBookmarkedCollege, b: IBookmarkedCollege): number =>
      b.tile_id.localeCompare(a.tile_id),
  });

export interface State extends EntityState<IBookmarkedCollege> {
  isLoading: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
});

export function Reducer(
  state = initialState,
  action: BookmarksActions.Actions | UserActions.Actions
): State {
  switch (action.type) {
    case BookmarksActions.ActionTypes.LIST_BOOKMARKS:
      return featureAdapter.removeAll({
        ...state,
        isLoading: true,
      });
    case BookmarksActions.ActionTypes.LIST_BOOKMARKS_SUCCESS:
      return featureAdapter.setAll(action.payload.colleges, {
        ...state,
        isLoading: false,
      });
    case BookmarksActions.ActionTypes.ADD_BOOKMARK_SUCCESS: {
      return featureAdapter.setOne(
        { ...action.payload.college, is_favorite: true },
        state
      );
    }
    case BookmarksActions.ActionTypes.REMOVE_BOOKMARK_SUCCESS: {
      const college = state.entities[action.payload.id];
      if (!college) {
        return state;
      }

      return featureAdapter.updateOne(
        {
          id: action.payload.id,
          changes: { is_favorite: false },
        },
        state
      );
    }
    case UserActions.ActionTypes.SIGN_OUT_USER_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
}

export const { selectAll: getBookmarks } = featureAdapter.getSelectors();

export const isLoading = (state: State): boolean => state.isLoading;
