<div class="login-popup d-flex flex-column text-center">
  <svg
    class="login-popup-logo"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 44 30"
  >
    <path
      fill="#47979D"
      fill-rule="evenodd"
      d="M32.5 20.73h-5.83V30H13.34C6.65 30 0 28.1 0 19.35c0-6.9 4.94-10.07 11.5-10.07h5.85V.02h.02V0h13.3C37.34 0 44 1.9 44 10.66c0 6.9-4.93 10.07-11.5 10.07zm-15.15-4.46h-4.51c-2.28 0-3.87.72-3.87 3.16 0 2.15 1.3 3.2 4.04 3.2h4.34v-6.36zM31 7.37h-4.34v6.36h4.5c2.28 0 3.87-.71 3.87-3.16 0-2.14-1.29-3.2-4.03-3.2z"
      clip-rule="evenodd"
    />
  </svg>
  <div class="login-popup-title">Log in</div>
  <form [formGroup]="authForm" (submit)="onSignin(); (false)">
    <input type="email" formControlName="email" placeholder="Email" />
    <input type="password" formControlName="password" placeholder="Password" />

    {{ error$ | async }}

    <div class="d-flex justify-content-center" *ngIf="isLoading$ | async">
      <app-loader-three-dots></app-loader-three-dots>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <label>
        <input type="checkbox" formControlName="isRememberMe" />
        <span>Remember me</span>
      </label>
      <a [href]="forgotPasswordURL" target="_blank">Forgot password?</a>
    </div>
    <div
      class="d-flex flex-column align-content-center text-center"
      *ngIf="(isLoading$ | async) == false"
    >
      <button class="btn">Log in</button>
      <!-- or
      <button class="btn alt">Continue with
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><path fill="#FBBB00" d="M3.1 8.46l-.48 1.82-1.79.04a6.97 6.97 0 01-.05-6.54l1.59.3.7 1.57a4.16 4.16 0 00.03 2.81z"/><path fill="#518EF8" d="M13.88 5.7a7 7 0 01-2.5 6.76l-2-.1-.28-1.77a4.17 4.17 0 001.8-2.13H7.15V5.7h6.73z"/><path fill="#28B446" d="M11.38 12.46A7 7 0 01.83 10.32L3.1 8.46a4.16 4.16 0 006 2.13l2.28 1.87z"/><path fill="#F14336" d="M11.47 1.61L9.2 3.47a4.16 4.16 0 00-6.14 2.18L.78 3.78a7 7 0 0110.69-2.17z"/></svg>
      </button> -->

      <div class="mt-3">
        New student? <a [href]="joinURL" target="_blank">Start here</a>
      </div>
    </div>
  </form>
</div>
