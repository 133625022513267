import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { UserFacade } from 'src/app/modules/user/store';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnDestroy {
  readonly error$ = new BehaviorSubject<null | string>(null);

  authForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', Validators.required),
    isRememberMe: new FormControl(false),
  });

  private readonly destroy$ = new Subject();

  constructor(
    private readonly dialogRef: MatDialogRef<LoginModalComponent>,
    private readonly userFacade: UserFacade
  ) {
    this.userFacade.error$
      .pipe(
        tap((_) => this.error$.next(_)),
        takeUntil(this.destroy$)
      )
      .subscribe((_) => _);

    this.userFacade.isAuthorized$
      .pipe(
        filter((_) => _),
        tap((_) => this.dialogRef.close())
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isLoading$(): Observable<boolean> {
    return this.userFacade.isLoading$;
  }

  get joinURL(): string {
    return new URL('#sign_up_modal', environment.rpEndpoint).href;
  }

  get forgotPasswordURL(): string {
    return new URL('#forgot_password_modal', environment.rpEndpoint).href;
  }

  onSignin(): void {
    if (!this.authForm.valid) {
      return;
    }

    this.userFacade.signin(
      this.authForm.value.email,
      this.authForm.value.password,
      this.authForm.value.isRememberMe
    );
  }
}
