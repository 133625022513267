import { IUser } from '../../model';
import { UserActions } from '../actions';

export interface State {
  user: null | IUser;
  token: null | string;
  isLoading: boolean;
  isPristine: boolean;
  error: null | string;
}

const initialState: State = {
  user: null,
  token: null,
  isLoading: false,
  isPristine: true,
  error: null,
};

export function Reducer(
  state = initialState,
  action: UserActions.Actions
): State {
  switch (action.type) {
    case UserActions.ActionTypes.SIGN_IN_USER:
      return {
        ...state,
        isLoading: true,
        isPristine: false,
      };
    case UserActions.ActionTypes.SIGN_IN_USER_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        error: null,
        isPristine: false,
      };
    case UserActions.ActionTypes.SIGN_IN_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
        isPristine: false,
      };
    case UserActions.ActionTypes.USER_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case UserActions.ActionTypes.USER_DATA_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isLoading: false,
        isPristine: false,
        error: null,
      };
    case UserActions.ActionTypes.USER_DATA_FAILURE:
      return {
        ...state,
        isPristine: false,
        error: action.payload.error,
      };
    case UserActions.ActionTypes.API_UNEXPECTED_ERROR: {
      if (!state.isLoading) {
        return state;
      }

      return {
        ...state,
        isLoading: false,
        isPristine: false,
        error: action.message,
      };
    }
    case UserActions.ActionTypes.SIGN_OUT_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
