import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as Resources from './reducers/resources.reducer';
import { State, resourcesFeatureKey } from './reducers';

export const resourcesModuleState =
  createFeatureSelector<State>(resourcesFeatureKey);

export const selectResourcesState = createSelector(
  resourcesModuleState,
  (state: State) => state.resources
);

export const selectResources = createSelector(
  selectResourcesState,
  Resources.getResources
);

export const selectAmountOfResourcesPages = createSelector(
  selectResourcesState,
  Resources.getAmountOfPages
);

export const selectCurrentPage = createSelector(
  selectResourcesState,
  Resources.currentPage
);

export const selectSearchTerm = createSelector(
  selectResourcesState,
  Resources.searchTerm
);

export const selectIsLoadingResources = createSelector(
  selectResourcesState,
  Resources.isLoading
);