import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CollegesStoreEffects } from './effects';
import { CollegesFacade } from './facade';
import { collegesFeatureKey, reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(collegesFeatureKey, reducers),
    EffectsModule.forFeature([CollegesStoreEffects]),
  ],
  providers: [CollegesFacade],
})
export class CollegesStoreModule {}
