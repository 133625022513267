import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import { ResourcesActions } from './actions';
import { ResourcesService } from './service';

@Injectable({
    providedIn: 'root',
})
export class ResourcesStoreEffects {
    constructor(
        private readonly _actions$: Actions,
        private readonly _resourcesService: ResourcesService
    ) { }

    listResources$ = createEffect(() =>
        this._actions$.pipe(
            ofType<ResourcesActions.ListResourcesAction>(
                ResourcesActions.ActionTypes.LIST_RESOURCES
            ),
            switchMap((_) =>
                this._resourcesService
                    .loadResources(_.request.page, _.request.searchTerm)
                    .pipe(
                        switchMap((_) => [
                            new ResourcesActions.ListResourcesSuccessAction(_),
                        ])
                    )
            )
        )
    );
}
