import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';

import { UserFacade } from 'src/app/modules/user/store';
import {
  ForbiddenError,
  UnauthorizedError,
  UnexpectedError,
} from 'src/app/modules/user/model';

interface IRequest {
  [key: string]: any;
}

interface IResponse {
  code: number;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private userFacade: UserFacade) {}

  intercept(
    request: HttpRequest<IRequest>,
    next: HttpHandler
  ): Observable<HttpEvent<IResponse>> {
    return this.userFacade.token$.pipe(
      take(1),
      map((_) => (_ ? request.clone({ setParams: { token: _ } }) : request)),
      mergeMap((_) => next.handle(_)),
      tap(this.onEvent, this.onError)
    );
  }

  private onEvent = (event: HttpEvent<IResponse>) => {
    if (!(event instanceof HttpResponse)) {
      return event;
    }

    const { body } = event;
    if (!body) {
      return event;
    }

    const error = this.getErrorForBodyRespose(body);
    if (error) {
      this.userFacade.error(error);
      throw error;
    }

    return event;
  };

  private onError = (event: HttpErrorResponse) => {
    const { error: body } = event;

    const error = this.getErrorForBodyRespose(body) || new UnexpectedError();

    this.userFacade.error(error);
    throw error;
  };

  private getErrorForBodyRespose(body: IResponse) {
    if (body.code === 401) {
      return new UnauthorizedError(body.message?.text);
    }

    if (body.code === 403) {
      return new ForbiddenError(body.message?.text);
    }

    if (body.code !== 200) {
      return new UnexpectedError();
    }

    return null;
  }
}
