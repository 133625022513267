import { Action } from '@ngrx/store';

import { ICollege } from '../../model';

export enum ActionTypes {
  LIST_COLLEGES = '[Colleges] List',
  LIST_COLLEGES_FAILURE = '[Colleges] List Failure',
  LIST_COLLEGES_SUCCESS = '[Colleges] List Success',
}

export class ListCollegesAction implements Action {
  readonly type = ActionTypes.LIST_COLLEGES;
  constructor(public request: { interests?: string[]; state?: string }) {}
}

export class ListCollegesSuccessAction implements Action {
  readonly type = ActionTypes.LIST_COLLEGES_SUCCESS;
  constructor(public payload: { colleges: ICollege[] }) {}
}

export class ListCollegesFailureAction implements Action {
  readonly type = ActionTypes.LIST_COLLEGES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | ListCollegesAction
  | ListCollegesSuccessAction
  | ListCollegesFailureAction;
