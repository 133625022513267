import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConvertNameService {
  private static readonly DELIMETER = '-';

  convertName(name = '', lastName = ''): string {
    return this.doConvertName(name, lastName, ConvertNameService.DELIMETER);
  }

  private doConvertName(name = '', lastName = '', delimeter: string): string {
    let entireName = name.substring(0, 98);

    if (lastName) {
      entireName = `${name} ${lastName}`;
    }

    if (/[а-яА-ЯЁё]/.test(entireName)) {
      entireName = this.transliterate(entireName);
    }

    return entireName.replace(/\s+/g, delimeter).toLowerCase();
  }

  private transliterate(word: string): string {
    let answer = '';
    const a: { [k: string]: string } = {};

    a['Ё'] = 'YO';
    a['Й'] = 'I';
    a['Ц'] = 'TS';
    a['У'] = 'U';
    a['К'] = 'K';
    a['Е'] = 'E';
    a['Н'] = 'N';
    a['Г'] = 'G';
    a['Ш'] = 'SH';
    a['Щ'] = 'SCH';
    a['З'] = 'Z';
    a['Х'] = 'H';
    a['Ъ'] = "'";
    a['ё'] = 'yo';
    a['й'] = 'i';
    a['ц'] = 'ts';
    a['у'] = 'u';
    a['к'] = 'k';
    a['е'] = 'e';
    a['н'] = 'n';
    a['г'] = 'g';
    a['ш'] = 'sh';
    a['щ'] = 'sch';
    a['з'] = 'z';
    a['х'] = 'h';
    a['ъ'] = "'";
    a['Ф'] = 'F';
    a['Ы'] = 'I';
    a['В'] = 'V';
    a['А'] = 'a';
    a['П'] = 'P';
    a['Р'] = 'R';
    a['О'] = 'O';
    a['Л'] = 'L';
    a['Д'] = 'D';
    a['Ж'] = 'ZH';
    a['Э'] = 'E';
    a['ф'] = 'f';
    a['ы'] = 'i';
    a['в'] = 'v';
    a['а'] = 'a';
    a['п'] = 'p';
    a['р'] = 'r';
    a['о'] = 'o';
    a['л'] = 'l';
    a['д'] = 'd';
    a['ж'] = 'zh';
    a['э'] = 'e';
    a['Я'] = 'Ya';
    a['Ч'] = 'CH';
    a['С'] = 'S';
    a['М'] = 'M';
    a['И'] = 'I';
    a['Т'] = 'T';
    a['Ь'] = "'";
    a['Б'] = 'B';
    a['Ю'] = 'YU';
    a['я'] = 'ya';
    a['ч'] = 'ch';
    a['с'] = 's';
    a['м'] = 'm';
    a['и'] = 'i';
    a['т'] = 't';
    a['ь'] = "'";
    a['б'] = 'b';
    a['ю'] = 'yu';

    for (let i = 0; i < word.length; i++) {
      if (a[word[i]] === undefined) {
        answer += word[i];
      } else {
        answer += a[word[i]];
      }
    }

    return answer;
  }
}
