import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import {
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { UserActions, UserInitActions } from './actions';
import { UserService } from './service';
import { State } from './reducers';
import { selectIsLoading } from './selectors';

@Injectable({
  providedIn: 'root',
})
export class UserStoreEffects {
  constructor(
    private readonly store: Store<State>,
    private readonly actions$: Actions,
    private readonly userService: UserService
  ) {}

  ngrxOnInitEffects(): Action {
    return new UserInitActions.InitUserAction();
  }

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserInitActions.InitUserAction>(
        UserInitActions.ActionTypes.INIT_USER
      ),
      mergeMap((_) => this.userService.token$),
      switchMap((_) =>
        _
          ? [
              new UserActions.SignInUserSuccessAction({ token: _ }),
              new UserActions.UserDataAction({ token: _ }),
            ]
          : []
      )
    )
  );

  signin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserActions.SignInUserAction>(
        UserActions.ActionTypes.SIGN_IN_USER
      ),
      switchMap((_) =>
        combineLatest([
          of(_),
          this.userService.signin$(_.payload.email, _.payload.password),
        ]).pipe(
          map((_) => ({
            request: _[0],
            token: _[1],
          })),
          tap((_) =>
            this.userService.setToken(_.token, _.request.payload.isRememberMe)
          )
        )
      )
    ),
    { dispatch: false }
  );

  listenSignout$ = createEffect(() =>
    this.userService.token$.pipe(
      filter(_ => !!_),
      take(1),
      concatMap(_ => this.userService.token$),
      switchMap((_) =>
        _
          ? []
          : [
            new UserActions.SignOutUserSuccessAction()
          ]
      )
    )
  );

  signout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserActions.SignOutAction>(UserActions.ActionTypes.SIGN_OUT_USER),
      tap((_) => this.userService.signout()),
      mergeMap((_) => [new UserActions.SignOutUserSuccessAction()])
    )
  );

  userData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserActions.UserDataAction>(UserActions.ActionTypes.USER_DATA),
      mergeMap((_) => this.userService.data$()),
      mergeMap((_) => [new UserActions.UserDataSuccessAction({ user: _ })])
    )
  );

  // TODO: place this logic in sep module called api
  unauthorizedError$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserActions.APIUnauthorizedErrorAction>(
        UserActions.ActionTypes.API_UNAUTHORIZED_ERROR
      ),
      withLatestFrom(this.store.pipe(select(selectIsLoading))),
      filter((_) => _[1]),
      mergeMap((_) => [
        new UserActions.SignOutAction(),
        new UserActions.SignInUserFailureAction({ error: _[0].message }),
      ])
    )
  );

  // TODO: place this logic in sep module called api
  forbiddenError$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserActions.APIForbiddenErrorAction>(
        UserActions.ActionTypes.API_FORBIDDEN_ERROR
      ),
      withLatestFrom(this.store.pipe(select(selectIsLoading))),
      filter((_) => _[1]),
      mergeMap((_) => [
        new UserActions.SignInUserFailureAction({ error: _[0].message }),
      ])
    )
  );
}
