import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserStoreEffects } from './effects';
import { UserFacade } from './facade';
import { userFeatureKey, reducer } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(userFeatureKey, reducer),
    EffectsModule.forFeature([UserStoreEffects]),
  ],
  providers: [UserFacade],
})
export class UserStoreModule {}
