import { omitBy, isEmpty } from 'lodash';

// TODO:
// same as in RP project, reuse

export class NotEmptyURLSearchParams extends URLSearchParams {
    constructor(params: { [k: string]: string }) {
        super(omitBy(params, isEmpty));
    }
}
