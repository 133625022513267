import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @Input() src?: string;
  @Input() placeholder?: string;

  get isDefaultImage(): boolean {
    if (!this.src) {
      return false;
    }

    const pathname = this.getPathname(this.src);

    return pathname.startsWith('/img/default');
  }

  private getPathname(src: string): string {
    try {
      const { pathname } = new URL(src);

      return pathname;
    } catch (_) {
      return src;
    }
  }
}
