import { Pipe, PipeTransform } from '@angular/core';

interface Value {
  type?: string;
  name: string;
  student?: {
    lastname: string;
  };
  teacher?: {
    lastname: string;
  };
}

@Pipe({
  name: 'initials',
})
export class InitialsPipe implements PipeTransform {
  transform(value: Value): undefined | string {
    if (!value) {
      return undefined;
    }

    const name = this.name(value);
    if (!name) {
      return undefined;
    }

    return this.short(name);
  }

  private short(name: string) {
    const parts = name.split(' ', 2).map((str) => str[0]);

    return parts.join(``).toUpperCase();
  }

  private name(value: Value) {
    const lastName = this.getLastName(value);
    const fullName = lastName ? `${value.name} ${lastName}` : value.name;

    return fullName;
  }

  private getLastName(value: Value) {
    switch (value.type) {
      case 'student':
        return value.student?.lastname || null;
      case 'teacher':
        return value.teacher?.lastname || null;
      default:
        return null;
    }
  }
}
