import { Component } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-pristine',
  templateUrl: './header-pristine.component.html',
})
export class HeaderPristineComponent {
  get rpURL(): string {
    return environment.rpEndpoint;
  }
}
