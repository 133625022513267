import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { LoginModalComponent } from 'src/app/shared/components';

@Component({
  selector: 'app-header-no-auth',
  templateUrl: './header-no-auth.component.html',
})
export class HeaderNoAuthComponent implements OnDestroy {
  private readonly destroy$ = new Subject();

  constructor(private readonly dialog: MatDialog) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get rpURL(): string {
    return environment.rpEndpoint;
  }

  get joinURL(): string {
    return new URL('#sign_up_modal', environment.rpEndpoint).href;
  }

  openLoginModal(): void {
    const dialogRef = this.dialog.open<LoginModalComponent>(
      LoginModalComponent,
      {
        panelClass: 'rp-dialog',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => _);
  }
}
