import { Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from 'src/environments/environment';
import { IUser } from 'src/app/modules/user/model';

@Component({
  selector: 'app-header-auth',
  templateUrl: './header-auth.component.html',
})
export class HeaderAuthComponent {
  @Input() user!: IUser;

  @Output() signout = new EventEmitter<void>();

  get rpURL(): string {
    return environment.rpEndpoint;
  }

  onSignout(): void {
    this.signout.emit();
  }
}
