import { Action } from '@ngrx/store';

import { IResource } from '../../model';

export enum ActionTypes {
  LIST_RESOURCES = '[Resources] List',
  LIST_RESOURCES_FAILURE = '[Resources] List Failure',
  LIST_RESOURCES_SUCCESS = '[Resources] List Success',
}

export class ListResourcesAction implements Action {
  readonly type = ActionTypes.LIST_RESOURCES;
  constructor(public request: { searchTerm: string, page: number }) {}
}

export class ListResourcesSuccessAction implements Action {
  readonly type = ActionTypes.LIST_RESOURCES_SUCCESS;
  constructor(public payload: { resources: IResource[], count: number }) {}
}

export class ListResourcesFailureAction implements Action {
  readonly type = ActionTypes.LIST_RESOURCES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | ListResourcesAction
  | ListResourcesSuccessAction
  | ListResourcesFailureAction;
