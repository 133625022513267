import { BaseUrlPipe } from './base-url/base-url.pipe';
import { InitialsPipe } from './initials/initials.component';
import { ProfileNameTitlePipe } from './profile-name-title/profile-name-title.pipe';
import { ProfileTypeTitlePipe } from './profile-type-title/profile-type-title.pipe';
import { TextTruncatePipe } from './text-truncate/text-truncate.pipe';

export {
  BaseUrlPipe,
  InitialsPipe,
  ProfileNameTitlePipe,
  ProfileTypeTitlePipe,
  TextTruncatePipe
};

export default [
  BaseUrlPipe,
  InitialsPipe,
  ProfileNameTitlePipe,
  ProfileTypeTitlePipe,
  TextTruncatePipe
];
