import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, userFeatureKey } from './reducers';

export const userModuleState = createFeatureSelector<State>(userFeatureKey);

export const selectUserState = createSelector(
  userModuleState,
  (state: State) => state
);

// TODO: make similar to colleges

export const selectUser = createSelector(
  selectUserState,
  (state) => state.user
);

export const selectToken = createSelector(
  selectUserState,
  (state) => state.token
);

export const selectIsLoading = createSelector(
  selectUserState,
  (state) => state.isLoading
);

export const selectIsPristine = createSelector(
  selectUserState,
  (state) => state.isPristine
);

export const selectError = createSelector(
  selectUserState,
  (state) => state.error
);

