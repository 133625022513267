import { favorite } from './svg/favorite';
import { favoriteInactive } from './svg/favorite-inactive';
import { academicCap } from './svg/academic-cap';
import { dollar } from './svg/dollar';
import { economy } from './svg/economy';
import { star } from './svg/star';
import { question } from './svg/question';
import {collegeResources} from "./svg/college-resources";
import {search} from "./svg/search";
import {arrow} from "./svg/arrow";
import {greenDownArrow} from "./svg/green-down-arrow";

export const icons = Object.freeze([
  favorite,
  favoriteInactive,
  academicCap,
  dollar,
  economy,
  star,
  question,
  collegeResources,
  search,
  arrow,
  greenDownArrow
]);
