import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICollege } from '../model';
import { IBookmarkedCollege } from '../model/IBookmarkedCollege';

import { BookmarksActions, CollegesActions } from './actions';
import { State } from './reducers';
import {
  selectBookmarks,
  selectBookmarksIsLoading,
  selectColleges,
  selectCollegesIsLoading,
} from './selectors';

@Injectable({
  providedIn: 'root',
})
export class CollegesFacade {
  readonly colleges$: Observable<Array<ICollege>>;
  readonly isCollegesLoading$: Observable<boolean>;
  readonly bookmarks$: Observable<Array<IBookmarkedCollege>>;
  readonly isBookmarksLoading$: Observable<boolean>;

  constructor(private readonly store: Store<State>) {
    this.colleges$ = this.store.pipe(select(selectColleges));
    this.isCollegesLoading$ = this.store.pipe(select(selectCollegesIsLoading));
    this.bookmarks$ = this.store.pipe(select(selectBookmarks));
    this.isBookmarksLoading$ = this.store.pipe(
      select(selectBookmarksIsLoading)
    );
  }

  search(request: { interests?: string[]; state?: string }): void {
    this.store.dispatch(new CollegesActions.ListCollegesAction(request));
  }

  listBookmarks(): void {
    this.store.dispatch(new BookmarksActions.ListBookmarksAction());
  }

  addBookmark(college: ICollege): void {
    this.store.dispatch(new BookmarksActions.AddBookmarkAction({ college }));
  }

  removeBookmark(id: string): void {
    this.store.dispatch(new BookmarksActions.RemoveBookmarkAction({ id }));
  }
}
