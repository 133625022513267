import { Pipe, PipeTransform } from '@angular/core';

interface Profile {
  type?: string;
  teacher?: {
    member: string;
  };
  org?: {
    member: string;
  };
}

@Pipe({
  name: 'profileTypeTitle',
})
export class ProfileTypeTitlePipe implements PipeTransform {
  transform(profile: Profile): undefined | string {
    switch (profile.type?.toLowerCase()) {
      case 'teacher':
        return profile.teacher?.member;
      case 'org':
        return profile.org?.member;
      default:
        return 'Student';
    }
  }
}
