import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { IconsModule } from 'src/app/icons/icons.module';


import Pipes from './pipes';
import Components from './components';
import Services from './services';
import Directives from './directives';
import Guards from './guards';

const MATERIALS = [
  MatDialogModule,
  MatSelectModule,
  MatInputModule,
  MatTooltipModule,
];

@NgModule({
  declarations: [...Pipes, ...Components, ...Directives],
  imports: [...MATERIALS, CommonModule, ReactiveFormsModule, IconsModule],
  exports: [...MATERIALS, ...Pipes, ...Components, ...Directives],
  providers: [...Pipes, ...Services, ...Guards],
})
export class SharedModule {}
