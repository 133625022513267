import { Directive, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, fromEvent, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { LoginModalComponent } from 'src/app/shared/components';
import { UserFacade } from 'src/app/modules/user/store';

@Directive({
  selector: '[appOpenLoginModalForNotAuthorized]',
})
export class OpenLoginModalForNotAuthoriedDirective
  implements OnInit, OnDestroy
{
  private destroy$ = new Subject();

  constructor(
    private readonly dialog: MatDialog,
    private readonly elRef: ElementRef,
    private readonly userFacade: UserFacade
  ) {}

  ngOnInit() {
    combineLatest([
      fromEvent<Event>(this.elRef.nativeElement, 'click', { capture: true }),
      this.userFacade.isAuthorized$.pipe(map((_) => !_)),
    ])
      .pipe(
        filter((_) => _[1]),
        tap((_) => {
          _[0].stopPropagation();

          this.openLoginModal();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((_) => _);
  }

  private openLoginModal() {
    const dialogRef = this.dialog.open<LoginModalComponent>(
      LoginModalComponent,
      {
        panelClass: 'rp-dialog',
      }
    );

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
