import { ActionReducerMap } from '@ngrx/store';

import {
  State as ResourcesState,
  Reducer as ResourcesReducer,
} from './resources.reducer';

import { ResourcesActions } from '../actions';

export interface State {
  resources: ResourcesState;
}

export const resourcesFeatureKey = 'resources';

export const reducers: ActionReducerMap<
  State,
  ResourcesActions.Actions
> = {
  resources: ResourcesReducer,
};
