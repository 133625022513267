import { Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUser } from 'src/app/modules/user/model';

import { UserFacade } from 'src/app/modules/user/store';

@Component({
  selector: 'app-header',
  templateUrl: './header.container.html',
})
export class HeaderContainer {
  constructor(private readonly userFacade: UserFacade) {}

  get user$(): Observable<null | IUser> {
    return this.userFacade.user$;
  }

  get isLoading$(): Observable<boolean> {
    return combineLatest([
      this.userFacade.isLoading$,
    ]).pipe(
      map(_ => _[0])
    );
  }

  get isPristine$(): Observable<boolean> {
    return combineLatest([
      this.userFacade.isPristine$
    ]).pipe(
      map(_ => _[0])
    );
  }

  get error$(): Observable<null | string> {
    return this.userFacade.error$;
  }

  onSignout(): void {
    this.userFacade.signout();
  }
}
