import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DevEnvGuard } from './shared/guards';

const routes: Routes = [
  {
    path: 'html-css',
    loadChildren: () =>
      import('./html-css/html-css.module').then((m) => m.HtmlCssModule),
    canLoad: [DevEnvGuard],
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('./modules/resources/resources.module').then(
        (m) => m.ResourcesModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/colleges/colleges.module').then(
        (m) => m.CollegesModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
