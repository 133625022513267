import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { CollegesFacade } from './modules/colleges/store';
import { UserFacade } from './modules/user/store';
import { UserActions } from './modules/user/store/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.actions$
      .pipe(
        ofType(UserActions.ActionTypes.SIGN_OUT_USER_SUCCESS),
        tap(this.onLogout),
        takeUntil(this.destroy$)
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onLogout = () => {
    this.router.navigate(['/']);
  };
}
