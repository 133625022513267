import { Pipe, PipeTransform } from '@angular/core';

import _ from 'lodash';

@Pipe({
  name: 'textTruncate'
})
export class TextTruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number = 30): string {
    return _.truncate(value, {
      length: maxLength
    });
  }
}