import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIService, TokenService } from 'src/app/shared/services';

import { IUser } from '../model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly token$ = this.tokenService.token$;

  constructor(
    private readonly tokenService: TokenService,
    private readonly apiService: APIService
  ) {}

  signin$(email: string, password: string): Observable<string> {
    return this.apiService
      .post<{ key: string }, { email: string; password: string }>(
        'auth/token',
        { email, password }
      )
      .pipe(map((_) => _.key));
  }

  signout(): void {
    this.tokenService.removeToken();
  }

  data$(): Observable<IUser> {
    return this.apiService
      .get<{ user: IUser }>('user')
      .pipe(map((_) => _.user));
  }

  setToken(token: string, isRememberMe: boolean): void {
    return this.tokenService.setToken(token, isRememberMe);
  }
}
