import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConvertIdService {
  convertIdStringToNumber(id: string): string {
    const chrArr = ['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm'];

    const idArray = id.split('');
    idArray.shift();

    const idNumber = idArray
      .map((item) => chrArr.findIndex((el) => el === item))
      .join('');

    return idNumber;
  }

  convertIdNumberToString(id: number | string): string {
    const chrArr = ['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm'];
    const idArray = id.toString(10).split('').map(Number);
    const idString = idArray.map((item) => chrArr[item]).join('');

    return `_${idString}`;
  }
}
