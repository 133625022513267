import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { environment } from 'src/environments/environment';
import { VERSION } from 'src/environments/version';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.setTag('type', 'frontend');
    Sentry.init({
      dsn: environment.sentryDsn,
      environment: environment.production ? 'production' : 'development',
      release: VERSION.raw,
    });
  }

  handleError(error: Error): void {
    Sentry.captureException(error);
  }
}
