import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { environment } from 'src/environments/environment';

import { ConvertNameService } from '../../services';

// TODO: similar logic within the RP project
// move to the shared lib

@Directive({
  selector: 'a[article]'
})
export class ArticleLinkDirective implements OnInit, OnDestroy {
  @Input() article!: { id: number, name: string};
  @Input() target: '_self' | '_blank' | '_parent' | '_top' = '_blank';

  private _destroy$ = new Subject<void>();

  constructor(
    private readonly _el: ElementRef,
    private readonly _router: Router,
    private readonly _convertNameService: ConvertNameService,
  ) { }

  ngOnInit() {
    this._el.nativeElement.href = new URL(
      this._router.createUrlTree(this.getArticleLink(this.article.id, this.article.name)).toString(),
      environment.rpEndpoint
    ).href;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private getArticleLink(id: number | string, name: string) {
    const link = ['/discussions', id, this._convertNameService.convertName(name)];
    return link;
  }
}
