import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as Colleges from './reducers/colleges.reducer';
import * as Bookmarks from './reducers/bookmarks.reducer';
import { State, collegesFeatureKey } from './reducers';

export const collegesModuleState =
  createFeatureSelector<State>(collegesFeatureKey);

export const selectCollegesState = createSelector(
  collegesModuleState,
  (state: State) => state.colleges
);

export const selectBookmarksState = createSelector(
  collegesModuleState,
  (state: State) => state.bookmarks
);

export const selectColleges = createSelector(
  selectCollegesState,
  Colleges.getColleges
);

export const selectCollegesIsLoading = createSelector(
  selectCollegesState,
  Colleges.isLoading
);

export const selectBookmarks = createSelector(
  selectBookmarksState,
  Bookmarks.getBookmarks
);

export const selectBookmarksIsLoading = createSelector(
  selectBookmarksState,
  Bookmarks.isLoading
);
