import { ElementRef, Directive, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[icon]',
})
export class IconsDirective implements OnChanges {
  @Input() icon = '';

  constructor(readonly hostRef: ElementRef<HTMLElement>) {}

  ngOnChanges() {
    if (!this.icon) {
      return;
    }

    const [iconId, viewBox] = this.icon.split(`:`);

    const host = this.hostRef.nativeElement;

    const viewBoxStr = viewBox ? ` viewBox="${viewBox}"` : ``;

    host.classList.add(`icon`, iconId);
    host.innerHTML = `<svg ${viewBoxStr}><use href="#${iconId}"></use></svg>`;
  }
}
