import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { UserActions } from 'src/app/modules/user/store/actions';

import { ICollege } from '../../model';
import { BookmarksActions, CollegesActions } from '../actions';

export const featureAdapter: EntityAdapter<ICollege> =
  createEntityAdapter<ICollege>({
    selectId: (model) => model.tile_id
  });

export interface State extends EntityState<ICollege> {
  isLoading: boolean;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
});

export function Reducer(
  state = initialState,
  action:
    | CollegesActions.Actions
    | BookmarksActions.Actions
    | UserActions.Actions
): State {
  switch (action.type) {
    case CollegesActions.ActionTypes.LIST_COLLEGES:
      return {
        ...state,
        isLoading: true,
      };
    case CollegesActions.ActionTypes.LIST_COLLEGES_SUCCESS:
      return featureAdapter.setAll(action.payload.colleges, {
        ...state,
        isLoading: false,
      });
    default:
      return state;
  }
}

export const { selectAll: getColleges } = featureAdapter.getSelectors();

export const isLoading = (state: State): boolean => state.isLoading;
