import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconsComponent } from './icons.component';
import { IconsDirective } from './icons.directive';

@NgModule({
  declarations: [IconsComponent, IconsDirective],
  imports: [CommonModule],
  exports: [IconsComponent, IconsDirective],
})
export class IconsModule {}
