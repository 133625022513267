import { Action } from '@ngrx/store';

export enum ActionTypes {
  INIT_USER = '[User] Init',
}

export class InitUserAction implements Action {
  readonly type = ActionTypes.INIT_USER;
}

export type Actions = InitUserAction;
