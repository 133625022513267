import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { UserFacade } from 'src/app/modules/user/store';

@Directive({
  selector: '[appHideForAuthorized]',
})
export class HideForAuthorizedDirective<T> implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  constructor(
    private readonly templateRef: TemplateRef<T>,
    private readonly viewContainer: ViewContainerRef,
    private readonly userFacade: UserFacade
  ) {}

  ngOnInit() {
    this.userFacade.isAuthorized$
      .pipe(
        tap((_) => (_ ? this.hide() : this.show())),
        takeUntil(this.destroy$)
      )
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private show() {
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private hide() {
    this.viewContainer.clear();
  }
}
