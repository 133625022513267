import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

// TODO: same logic within the RP project
// move to the shared lib

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() currentPage!: number;
  @Input() amountOfPages!: number;
  @Input() navigation: boolean = true;

  @Output() setPage = new EventEmitter<number>();

  constructor(
    private router: Router
  ) { }

  get isValid() {
    return !!this.currentPage && !!this.amountOfPages;
  }

  onChangePageNumber(e: Event) {
    const page = (e.target as HTMLInputElement).value;

    this.currentPage = this.convertPageToNumber(page);
  }

  onSetPage() {
    if (this.currentPage < 1) {
      this.currentPage = 1;
    }

    const page = this.amountOfPages < this.currentPage ? this.amountOfPages : this.currentPage;

    this.setPage.emit(page);

    if (!this.navigation) {
      return;
    }

    const urlTree = this.router.createUrlTree([], {
      queryParams: { page },
      queryParamsHandling: 'merge',
      preserveFragment: true
    });

    this.router.navigateByUrl(urlTree);
  }

  onNextPage() {
    this.currentPage = this.amountOfPages < this.currentPage + 1 ? this.amountOfPages : this.currentPage + 1;

    this.onSetPage();
  }

  onPrevPage() {
    this.currentPage = this.currentPage - 1 < 1 ? 1 : this.currentPage - 1;

    this.onSetPage();
  }

  private convertPageToNumber(page: any) {
    const nPage = Number(page);

    if (Number.isSafeInteger(nPage)) {
      return nPage;
    }

    return 1;
  }

}
