import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IResource } from '../../model';
import { ResourcesActions } from '../actions';

const RESOURCES_PER_PAGE = 20;

export const featureAdapter: EntityAdapter<IResource> =
  createEntityAdapter<IResource>({
    selectId: (model) => model.id
  });

export interface State extends EntityState<IResource> {
  isLoading: boolean;
  amountOfPages: number;
  currentPage: number;
  searchTerm: string;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  amountOfPages: 0,
  currentPage: 1,
  searchTerm: ''
});

export function Reducer(
  state = initialState,
  action:
    | ResourcesActions.Actions
): State {
  switch (action.type) {
    case ResourcesActions.ActionTypes.LIST_RESOURCES:
      return {
        ...state,
        isLoading: true,
        currentPage: action.request.page,
        searchTerm: action.request.searchTerm
      };
    case ResourcesActions.ActionTypes.LIST_RESOURCES_SUCCESS:
      return featureAdapter.setAll(action.payload.resources, {
        ...state,
        isLoading: false,
        amountOfPages: Math.ceil(action.payload.count / RESOURCES_PER_PAGE)
      });
    default:
      return state;
  }
}

export const { selectAll: getResources } = featureAdapter.getSelectors();

export const getAmountOfPages = (state: State): number => state.amountOfPages;
export const currentPage = (state: State): number => state.currentPage;
export const searchTerm = (state: State): string => state.searchTerm;
export const isLoading = (state: State): boolean => state.isLoading;
