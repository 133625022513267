import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevEnvGuard implements CanLoad {
  constructor(private readonly router: Router) {}

  canLoad(): Observable<true | UrlTree> {
    return of(!environment.production).pipe(
      map((_) => (_ ? true : this.router.createUrlTree([''])))
    );
  }
}
