// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endpoint: 'https://dev.colleges.roundpier.com',
  s3Endpoint: 'https://s3.colleges.dev.roundpier.com',
  rpEndpoint: 'https://www.dev.roundpier.com',
  domain: 'roundpier.com',
  tokenName: 'token-dev',
  sentryDsn:
    'https://0b2f996dfd3c4a64b2a5a47c36cae728@o974232.ingest.sentry.io/5928836',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
