import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { UserStoreModule } from 'src/app/modules/user/store/user-store.module';
import { CollegesStoreModule } from 'src/app/modules/colleges/store/colleges-store.module';
import { ResourcesStoreModule } from 'src/app/modules/resources/store';

@NgModule({
  imports: [
    CommonModule,
    UserStoreModule,
    CollegesStoreModule,
    ResourcesStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
  ],
  declarations: [],
})
export class RootStoreModule {}
