<ng-container *ngIf="isLoading$ | async; else touched">
  <app-header-pristine></app-header-pristine>
</ng-container>
<ng-template #touched>
  <ng-container *ngIf="user$ | async as user; else noAuth">
    <app-header-auth [user]="user" (signout)="onSignout()"></app-header-auth>
  </ng-container>
  <ng-template #noAuth>
    <app-header-no-auth></app-header-no-auth>
  </ng-template>
</ng-template>
