import { Action } from '@ngrx/store';

import { IBookmarkedCollege, ICollege } from '../../model';

export enum ActionTypes {
  ADD_BOOKMARK = '[Bookmarks] Add',
  ADD_BOOKMARK_FAILURE = '[Bookmarks] Add Failure',
  ADD_BOOKMARK_SUCCESS = '[Bookmarks] Add Success',

  REMOVE_BOOKMARK = '[Bookmarks] Remove',
  REMOVE_BOOKMARK_FAILURE = '[Bookmarks] Remove Failure',
  REMOVE_BOOKMARK_SUCCESS = '[Bookmarks] Remove Success',

  LIST_BOOKMARKS = '[Bookmarks] List',
  LIST_BOOKMARKS_FAILURE = '[Bookmarks] List Failure',
  LIST_BOOKMARKS_SUCCESS = '[Bookmarks] List Success',
}

export class AddBookmarkAction implements Action {
  readonly type = ActionTypes.ADD_BOOKMARK;
  constructor(public payload: { college: ICollege }) {}
}

export class AddBookmarkSuccessAction implements Action {
  readonly type = ActionTypes.ADD_BOOKMARK_SUCCESS;
  constructor(public payload: { college: ICollege }) {}
}

export class AddBookmarkFailureAction implements Action {
  readonly type = ActionTypes.ADD_BOOKMARK_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class RemoveBookmarkAction implements Action {
  readonly type = ActionTypes.REMOVE_BOOKMARK;
  constructor(public payload: { id: string }) {}
}

export class RemoveBookmarkSuccessAction implements Action {
  readonly type = ActionTypes.REMOVE_BOOKMARK_SUCCESS;
  constructor(public payload: { id: string }) {}
}

export class RemoveBookmarkFailureAction implements Action {
  readonly type = ActionTypes.REMOVE_BOOKMARK_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class ListBookmarksAction implements Action {
  readonly type = ActionTypes.LIST_BOOKMARKS;
}

export class ListBookmarksSuccessAction implements Action {
  readonly type = ActionTypes.LIST_BOOKMARKS_SUCCESS;
  constructor(public payload: { colleges: IBookmarkedCollege[] }) {}
}

export class ListBookmarksFailureAction implements Action {
  readonly type = ActionTypes.LIST_BOOKMARKS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export type Actions =
  | AddBookmarkAction
  | AddBookmarkSuccessAction
  | AddBookmarkFailureAction
  | RemoveBookmarkAction
  | RemoveBookmarkSuccessAction
  | RemoveBookmarkFailureAction
  | ListBookmarksAction
  | ListBookmarksSuccessAction
  | ListBookmarksFailureAction;
