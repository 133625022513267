import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CollegesService } from './service';
import { BookmarksActions, CollegesActions } from './actions';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CollegesStoreEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly collegesService: CollegesService
  ) {}

  searchColleges$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CollegesActions.ListCollegesAction>(
        CollegesActions.ActionTypes.LIST_COLLEGES
      ),
      switchMap((_) =>
        this.collegesService
          .searchColleges(_.request)
          .pipe(
            switchMap((_) => [
              new CollegesActions.ListCollegesSuccessAction({ colleges: _ }),
            ])
          )
      )
    )
  );

  listBookmarks$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CollegesActions.ListCollegesAction>(
        BookmarksActions.ActionTypes.LIST_BOOKMARKS
      ),
      switchMap((_) =>
        this.collegesService
          .listBookmarks()
          .pipe(
            switchMap((_) => [
              new BookmarksActions.ListBookmarksSuccessAction({ colleges: _ }),
            ])
          )
      )
    )
  );

  addBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BookmarksActions.AddBookmarkAction>(
        BookmarksActions.ActionTypes.ADD_BOOKMARK
      ),
      switchMap(({ payload: { college } }) =>
        this.collegesService
          .addBookmark(college.tile_id)
          .pipe(
            switchMap((_) => [
              new BookmarksActions.AddBookmarkSuccessAction({ college }),
            ])
          )
      )
    )
  );

  removeBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BookmarksActions.RemoveBookmarkAction>(
        BookmarksActions.ActionTypes.REMOVE_BOOKMARK
      ),
      switchMap(({ payload: { id } }) =>
        this.collegesService
          .removeBookmark(id)
          .pipe(
            switchMap((_) => [
              new BookmarksActions.RemoveBookmarkSuccessAction({ id }),
            ])
          )
      )
    )
  );
}
