import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ForbiddenError,
  IUser,
  UnauthorizedError,
  UnexpectedError,
} from '../model';
import { UserActions } from './actions';
import {
  APIForbiddenErrorAction,
  APIUnauthorizedErrorAction,
  APIUnexpectedErrorAction,
} from './actions/user.action';
import { State } from './reducers';
import {
  selectError,
  selectIsLoading,
  selectIsPristine,
  selectToken,
  selectUser,
} from './selectors';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {
  readonly user$: Observable<null | IUser>;
  readonly token$: Observable<null | string>;
  readonly isLoading$: Observable<boolean>;
  readonly isPristine$: Observable<boolean>;
  readonly error$: Observable<null | string>;

  constructor(private readonly store: Store<State>) {
    this.user$ = this.store.pipe(select(selectUser));
    this.token$ = this.store.pipe(select(selectToken));
    this.isLoading$ = this.store.pipe(select(selectIsLoading));
    this.isPristine$ = this.store.pipe(select(selectIsPristine));
    this.error$ = this.store.pipe(select(selectError));
  }

  get isAuthorized$(): Observable<boolean> {
    return this.token$.pipe(map((_) => !!_));
  }

  signin(email: string, password: string, isRememberMe: boolean): void {
    this.store.dispatch(
      new UserActions.SignInUserAction({ email, password, isRememberMe })
    );
  }

  signout(): void {
    this.store.dispatch(new UserActions.SignOutAction());
  }

  // move to APIFacade
  error(error: ForbiddenError | UnexpectedError | UnexpectedError): void {
    switch (true) {
      case error instanceof ForbiddenError:
        return this.store.dispatch(new APIForbiddenErrorAction(error.message));
      case error instanceof UnauthorizedError:
        return this.store.dispatch(
          new APIUnauthorizedErrorAction(error.message)
        );
      default:
        return this.store.dispatch(new APIUnexpectedErrorAction(error.message));
    }
  }
}
