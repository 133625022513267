import { ActionReducerMap } from '@ngrx/store';

import {
  State as CollegesState,
  Reducer as CollegesReducer,
} from './colleges.reducer';

import {
  State as BookmarksState,
  Reducer as BookmarksReducer,
} from './bookmarks.reducer';
import { BookmarksActions, CollegesActions } from '../actions';

export interface State {
  colleges: CollegesState;
  bookmarks: BookmarksState;
}

export const collegesFeatureKey = 'colleges';

export const reducers: ActionReducerMap<
  State,
  CollegesActions.Actions & BookmarksActions.Actions
> = {
  colleges: CollegesReducer,
  bookmarks: BookmarksReducer,
};
