import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from './reducers';
import { ResourcesActions } from './actions';
import { selectIsLoadingResources, selectResources, selectAmountOfResourcesPages, selectCurrentPage, selectSearchTerm } from './selectors';
import { IResource } from '../model';

@Injectable({
    providedIn: 'root',
})
export class ResourcesFacade {
    readonly resources$: Observable<IResource[]>;
    readonly resourcesAmountOfPages$: Observable<number>;
    readonly resourcesCurrentPage$: Observable<number>;
    readonly resourcesSearchTerm$: Observable<string>;
    readonly isLoadingResources$: Observable<boolean>;

    constructor(
        private readonly store: Store<State>
    ) {
        this.resources$ = this.store.pipe(select(selectResources));
        this.resourcesAmountOfPages$ = this.store.pipe(select(selectAmountOfResourcesPages));
        this.isLoadingResources$ = this.store.pipe(select(selectIsLoadingResources));
        this.resourcesCurrentPage$ = this.store.pipe(select(selectCurrentPage));
        this.resourcesSearchTerm$ = this.store.pipe(select(selectSearchTerm));
    }

    loadResources(params: { page: number, searchTerm: string } = { page: 1, searchTerm: '' }) {
        this.store.dispatch(new ResourcesActions.ListResourcesAction({
            page: params.page,
            searchTerm: params.searchTerm
        }));
    }
}
