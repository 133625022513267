import { Action } from '@ngrx/store';

import { IUser } from '../../model';

export enum ActionTypes {
  SIGN_IN_USER = '[User] Sign In',
  SIGN_IN_USER_FAILURE = '[User] Sign In Failure',
  SIGN_IN_USER_SUCCESS = '[User] Sign In Success',

  SIGN_OUT_USER = '[User] Sign Out',
  SIGN_OUT_USER_SUCCESS = '[User] Sign Out Success',

  USER_DATA = '[User] Data',
  USER_DATA_FAILURE = '[User] Data Failure',
  USER_DATA_SUCCESS = '[User] Data Success',

  // TODO: move to API
  API_FORBIDDEN_ERROR = '[API] Forbidden Error',
  API_UNAUTHORIZED_ERROR = '[API] Unauthorized Error',
  API_UNEXPECTED_ERROR = '[API] Unexpected Error',
}

export class SignInUserAction implements Action {
  readonly type = ActionTypes.SIGN_IN_USER;
  constructor(
    public payload: { email: string; password: string; isRememberMe: boolean }
  ) {}
}

export class SignInUserSuccessAction implements Action {
  readonly type = ActionTypes.SIGN_IN_USER_SUCCESS;
  constructor(public payload: { token: string }) {}
}

export class SignInUserFailureAction implements Action {
  readonly type = ActionTypes.SIGN_IN_USER_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UserDataAction implements Action {
  readonly type = ActionTypes.USER_DATA;
  constructor(public payload: { token: string }) {}
}

export class UserDataSuccessAction implements Action {
  readonly type = ActionTypes.USER_DATA_SUCCESS;
  constructor(public payload: { user: IUser }) {}
}

export class UserDataFailureAction implements Action {
  readonly type = ActionTypes.USER_DATA_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SignOutAction implements Action {
  readonly type = ActionTypes.SIGN_OUT_USER;
}

export class SignOutUserSuccessAction implements Action {
  readonly type = ActionTypes.SIGN_OUT_USER_SUCCESS;
}

export class APIForbiddenErrorAction implements Action {
  readonly type = ActionTypes.API_FORBIDDEN_ERROR;
  constructor(public message: string) {}
}

export class APIUnauthorizedErrorAction implements Action {
  readonly type = ActionTypes.API_UNAUTHORIZED_ERROR;
  constructor(public message: string) {}
}

export class APIUnexpectedErrorAction implements Action {
  readonly type = ActionTypes.API_UNEXPECTED_ERROR;
  constructor(public message: string) {}
}

export type Actions =
  | SignInUserAction
  | SignInUserSuccessAction
  | SignInUserFailureAction
  | UserDataAction
  | UserDataSuccessAction
  | UserDataFailureAction
  | SignOutAction
  | SignOutUserSuccessAction
  | APIForbiddenErrorAction
  | APIUnauthorizedErrorAction
  | APIUnexpectedErrorAction;
