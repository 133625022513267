import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APIService } from 'src/app/shared/services';

import { IBookmarkedCollege, ICollege } from '../model';

@Injectable({
  providedIn: 'root',
})
export class CollegesService {
  constructor(private readonly apiService: APIService) {}

  searchColleges(request: {
    interests?: string[];
    state?: string;
  }): Observable<ICollege[]> {
    const interests = request.interests?.length ? request.interests : [''];
    const state = request.state || null;

    return this.apiService
      .post<Array<ICollege>, { matches: Array<string>; state: null | string }>(
        'colleges/match',
        {
          matches: interests,
          state: state,
        }
      )
      .pipe(map((_) => _));
  }

  listBookmarks(): Observable<IBookmarkedCollege[]> {
    return this.apiService
      .get<Array<ICollege>>('colleges')
      .pipe(map((_) => _.map((_) => ({ ..._, is_favorite: true }))));
  }

  addBookmark(id: string): Observable<void> {
    return this.apiService
      .post<void, { id: string }>('colleges/add', { id })
      .pipe(map((_) => _));
  }

  removeBookmark(id: string): Observable<void> {
    return this.apiService
      .post<void, { id: string }>('colleges/del', { id })
      .pipe(map((_) => _));
  }
}
