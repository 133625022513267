import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import Cookies from 'js-cookie';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly tokenName = environment.tokenName;
  readonly token$ = new BehaviorSubject<null | string>(this._token());

  private readonly destroy$ = new Subject();

  constructor() {
    this.monitorToken$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((_) => _);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setToken(token: string, isRememberMe: boolean): void {
    this._setToken(token, isRememberMe);

    this.token$.next(token);
  }

  removeToken(): void {
    this._removeToken();

    this.token$.next(null);
  }

  private monitorToken$(): Observable<string|null> {
    return interval(500).pipe(
      map(() => this._token()),
      distinctUntilChanged(),
      tap((_) => this.token$.value !== _ ? this.token$.next(_) : null)
    );
  }

  private _token(): null | string {
    return Cookies.get(this.tokenName) || null;
  }

  private _setToken(token: string, isRememberMe: boolean): void {
    const options: { expires?: number; domain?: string } = {
      expires: isRememberMe ? 365 : undefined,
    };

    if (environment.domain) {
      options.domain = environment.domain;
    }

    Cookies.set(this.tokenName, token, options);
  }

  private _removeToken(): void {
    const options: { domain?: string } = {};

    if (environment.domain) {
      options.domain = environment.domain;
    }

    Cookies.remove(this.tokenName, options);
  }
}
