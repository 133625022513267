import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

interface Response<T> {
  code: number;
  status: 'ok' | 'error';
  response: T;
  message?: { text: string; type: string };
}

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(protected httpClient: HttpClient) {}

  get<T>(path: string, version: 'v1' | 'v2' = 'v1'): Observable<T> {
    const endpoint = this.getEndpointForVersion(version);

    const url = new URL(path, endpoint);

    return this.httpClient
      .get<Response<T>>(url.href)
      .pipe(share(), map(this.handleResponse));
  }

  post<T, D>(path: string, data: D, version: 'v1' | 'v2' = 'v1'): Observable<T> {
    const endpoint = this.getEndpointForVersion(version);

    const url = new URL(path, endpoint);

    return this.httpClient
      .post<Response<T>>(url.href, data)
      .pipe(share(), map(this.handleResponse));
  }

  handleResponse<T>(response: Response<T>): T {
    return response.response;
  }

  private getEndpointForVersion(version: 'v1' | 'v2') {
    switch (version) {
      case 'v1':
        return `${environment.rpEndpoint}/api/`;
      case 'v2':
        return `${environment.rpEndpoint}/api/v2/`;
      default:
        throw new Error('Invalid endpoint version');
    }
  }
}
