import { Directive, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { ProfileNameTitlePipe } from '../../pipes';
import { ConvertIdService, ConvertNameService } from '../../services';

export interface IProfile {
  id: number;
  type?: string;
  name: string;
  student?: {
    lastname: string;
  };
  teacher?: {
    lastname: string;
  };
}

@Directive({
  selector: 'a[appProfileLinkDirective]',
})
export class ProfileLinkDirective {
  @Input('appProfileLinkDirective') profile!: IProfile;

  constructor(
    private readonly elRef: ElementRef,
    private readonly router: Router,
    private readonly convertNameService: ConvertNameService,
    private readonly convertIdService: ConvertIdService,
    private readonly profileNameTitlePipe: ProfileNameTitlePipe
  ) {}

  ngOnInit() {
    const link = this.generateProfileLink(this.profile);

    this.elRef.nativeElement.href = new URL(
      this.router.createUrlTree(link).toString(),
      environment.rpEndpoint
    ).href;
    this.elRef.nativeElement.target = '_blank';
  }

  generateProfileLink(profile: IProfile): (string | null)[] {
    const userName = this.convertUserName(profile);

    return [
      this.convertIdService.convertIdNumberToString(profile.id),
      userName,
    ];
  }

  private convertUserName(profile: IProfile) {
    const names = this.profileNameTitlePipe.getNames(profile);
    if (!names) {
      return null;
    }

    return this.convertNameService.convertName(names.firstname, names.lastname);
  }
}
