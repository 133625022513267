import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { APIService } from 'src/app/shared/services';
import { NotEmptyURLSearchParams } from 'src/app/shared/types/not-empty-url-search-params.type';

import { IResource } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
    constructor(private readonly _apiService: APIService) {}

    loadResources(page: number, term: string) {
        const params = new NotEmptyURLSearchParams({
            page: page + '',
            text: term
        });
      
        return this._apiService.get<{discussions: IResource[], count: number }>(`eduniverse/college-questions?${params}`, 'v2').pipe(
            map(_ => ({
                count: _.count,
                resources: _.discussions
            }))
        );
    }
}
